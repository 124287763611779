import { default as atmosferaV144Mt9LAwMeta } from "/vercel/path0/pages/atmosfera.vue?macro=true";
import { default as banket62DQZ7v15hMeta } from "/vercel/path0/pages/banket.vue?macro=true";
import { default as booking0qczAHjo7zMeta } from "/vercel/path0/pages/booking.vue?macro=true";
import { default as contactsvmO9gwdyLiMeta } from "/vercel/path0/pages/contacts.vue?macro=true";
import { default as eventsiPr0A4oVc9Meta } from "/vercel/path0/pages/events.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as promo2GKplq5r88Meta } from "/vercel/path0/pages/promo.vue?macro=true";
export default [
  {
    name: atmosferaV144Mt9LAwMeta?.name ?? "atmosfera",
    path: atmosferaV144Mt9LAwMeta?.path ?? "/atmosfera",
    meta: atmosferaV144Mt9LAwMeta || {},
    alias: atmosferaV144Mt9LAwMeta?.alias || [],
    redirect: atmosferaV144Mt9LAwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/atmosfera.vue").then(m => m.default || m)
  },
  {
    name: banket62DQZ7v15hMeta?.name ?? "banket",
    path: banket62DQZ7v15hMeta?.path ?? "/banket",
    meta: banket62DQZ7v15hMeta || {},
    alias: banket62DQZ7v15hMeta?.alias || [],
    redirect: banket62DQZ7v15hMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/banket.vue").then(m => m.default || m)
  },
  {
    name: booking0qczAHjo7zMeta?.name ?? "booking",
    path: booking0qczAHjo7zMeta?.path ?? "/booking",
    meta: booking0qczAHjo7zMeta || {},
    alias: booking0qczAHjo7zMeta?.alias || [],
    redirect: booking0qczAHjo7zMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/booking.vue").then(m => m.default || m)
  },
  {
    name: contactsvmO9gwdyLiMeta?.name ?? "contacts",
    path: contactsvmO9gwdyLiMeta?.path ?? "/contacts",
    meta: contactsvmO9gwdyLiMeta || {},
    alias: contactsvmO9gwdyLiMeta?.alias || [],
    redirect: contactsvmO9gwdyLiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: eventsiPr0A4oVc9Meta?.name ?? "events",
    path: eventsiPr0A4oVc9Meta?.path ?? "/events",
    meta: eventsiPr0A4oVc9Meta || {},
    alias: eventsiPr0A4oVc9Meta?.alias || [],
    redirect: eventsiPr0A4oVc9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/events.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: promo2GKplq5r88Meta?.name ?? "promo",
    path: promo2GKplq5r88Meta?.path ?? "/promo",
    meta: promo2GKplq5r88Meta || {},
    alias: promo2GKplq5r88Meta?.alias || [],
    redirect: promo2GKplq5r88Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/promo.vue").then(m => m.default || m)
  }
]